<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.inbox') }}</b>
    </h3>
    <hr />
    <h3>
      <b>17.1 Melakukan pembelian melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Akun” di bagian kanan bawah melalui Aplikasi Mobile (iOS/Android)
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pilih Opsi “Agen Pilihan”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-1.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Klik Tombol Merah “+ Beli Slot Agen Pilihan”</li>
      <br />
      <li>
        Silahkan pilih detail “Provinsi”, “Kota/Kabupaten”, dan “Area” yang anda inginkan.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-2.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Akan muncul informasi Bulan dan Sisa Slot Agen Pilihan (dari total 10 slot) yang tersedia.
      </li>
      <br />
      <li>Klik Tombol Merah “+Beli Slot Agen Pilihan”</li>
      <br />
      <li>
        Akan muncul informasi detail pembelian fitur “Agen Pilihan” serta konsumsi koin yang
        dibutuhkan.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-3.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih “Beli Sekarang”</li>
    </ul>
    <hr />
    <h3>
      <b>17.2 Melakukan pembelian melalui Browser Laptop/PC</b>
    </h3>
    <br />
    <ul>
      <li>
        Di bagian beranda, pada bagian atas menu Anda akan menemukan bagian “Agen Pilihan”, atau
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-4.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Klik menu profile di sisi kanan atas halaman beranda dan pilih “Agen Pilihan”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-5.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Tekan tombol “+ Beli Slot Agen Pilihan”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-6.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Silahkan pilih detail “Provinsi”, “Kota/Kabupaten”, dan “Area” yang anda inginkan dan tekan
        tombol “Beli Slot Agen Pilihan”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-7.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Akan muncul informasi detail pembelian fitur “Agen Pilihan” serta konsumi koin yang
        dibutuhkan.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/featured-agent-8.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih “Beli Sekarang”</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
